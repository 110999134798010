import React, { useState } from 'react';
import { Card, Popover, Checkbox, Button, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

function TechCardPopover({onRemove, isRemoveLoading}) {
  const [selected, setSelected] = useState({major: true, minor: true, patch: true, alpha: false, beta: false, rc: false})
  const flipSelected = (tag) => () => setSelected({...selected, [tag]: !selected[tag]})

  return (
    <div style={{width: '13rem'}}>
      <div className="pb-1 mb-3" style={{borderBottom: '1px solid #e5e5e5', fontWeight: '600'}}>Preferences</div>
        <div className="pb-3 mb-3 inline-flex flex-wrap gap-x-2 gap-y-2" style={{borderBottom: '1px solid #e5e5e5', fontSize: '10px'}}>
          {Object.entries(selected).map(([tag, isSelected]) => <Tag style={{margin: 0, cursor: 'pointer', display: 'flex', alignItems: 'center'}} color={selected[tag] ? 'green' : 'orange'} defaultChecked onClick={flipSelected(tag)}>{tag} {isSelected && <CheckCircleOutlined className="ml-1" />}</Tag>)}
        </div>
      <div style={{textAlign: 'center'}}><Button danger style={{height: '2rem', flexDirection: 'column', justifyContent: 'center'}} onClick={() => onRemove && onRemove()} loading={isRemoveLoading}>Remove</Button></div>
    </div>
  );
}

export default TechCardPopover;
