import React, { useMemo, useState } from 'react';

import {
  useSetRecoilState,
  useRecoilValueLoadable,
} from 'recoil';

import { Button, Card, notification, Tag } from 'antd';

import { getAuth } from "firebase/auth";

import { doc, setDoc, getFirestore, updateDoc } from "firebase/firestore";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { currentPlanState } from '../../atoms/atoms';

const Context = React.createContext({ name: 'Default' });

function PremiumCard({ tier, period, price, features, isSelected, upgradable, cancelable, renews, setSignupVisible, isLoggedIn }) {
  const setCurrentPlan = useSetRecoilState(currentPlanState)
  const { contents: currentPlan, state: currentPlanLoading } = useRecoilValueLoadable(currentPlanState)

  const [isLoading, setIsLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification();

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  const openNotification = () => {
    api.info({
      message: `Plan upgraded`,
      description: <Context.Consumer>{({ name }) => `Your plan was upgraded.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openWillRenewNotification = () => {
    api.info({
      message: `Plan will renew`,
      description: <Context.Consumer>{({ name }) => `Your plan will renew on due date.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openWarnNotification = () => {
    api.warning({
      message: `Plan canceled`,
      description: <Context.Consumer>{({ name }) => `Your plan will not renew.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openRegisterNotification = () => {
    api.warning({
      message: `Please register`,
      description: <Context.Consumer>{({ name }) => `Register first to access premium membership.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openErrorNotification = () => {
    api.error({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Failed to save.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const renewDate = currentPlan?.expiry && (currentPlan.expiry?.toDate ? currentPlan.expiry?.toDate().toLocaleDateString() : currentPlan.expiry.toLocaleDateString())

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Card className="premium-card" bodyStyle={{height: '100%'}}>
        <div className='premium-card-container'>
          <div className="premium-card-top">
            {/* <img src={kubernetes} alt="" className='premium-card-image' /> */}
            <div  className='premium-card-head'>
              <span className='premium-card-head-text'>{tier[0]}</span>
            </div>
            <div className="premium-card-tier">{tier}{period && ` - ${period} Months`}</div>
            <div className="premium-card-price">${price} / month</div>
            <div className="premium-card-features">
              {features.map((f) => (
                <div key={f} className='premium-card-feature'>
                  <FontAwesomeIcon icon={faCheck} />
                  <span style={{marginLeft: 8}}>{f}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="premium-card-bottom">
            {(!isSelected || !isLoggedIn) ?
              (upgradable || !isLoggedIn) && <Button loading={isLoading} type="primary" className="tag-primary" onClick={async () => {
                await getAuth().authStateReady()
                const currentUser = getAuth().currentUser
                if (!currentUser) {
                  setSignupVisible(true)
                  setIsLoading(false)
                  return
                }

                setIsLoading(true)

                try {
                  const oneYearFromNoew = new Date()
                  oneYearFromNoew.setDate(oneYearFromNoew.getDate() + 365)

                  const userSubDocRef = doc(getFirestore(), 'premium', currentUser.uid)
                  await setDoc(userSubDocRef, { plan: tier, expiry: oneYearFromNoew, renews: true })
                  setCurrentPlan({ plan: tier, expiry: oneYearFromNoew, renews: true })
                  openNotification()
                } catch(e) {
                  console.log(e)
                  openErrorNotification()
                  return
                } finally {
                  setIsLoading(false)
                }
              }}>{isLoggedIn ? <>Upgrade Now</> : <>Register Now</>}</Button> :
              <div>
                <Tag color="green">This is your current plan</Tag>
                { currentPlanLoading === 'hasValue' && currentPlan.plan !== 'Free' && <div style={{marginTop: '0.75rem', fontSize: '12px'}}>({renews ? <>renews on {renewDate}</> : <>plan will not renew{renewDate && ` after ${renewDate}`}</>})</div>}
                {cancelable && currentPlanLoading === 'hasValue' && <div style={{marginTop: '1rem'}}><Button loading={isLoading} onClick={async () => {
                  await getAuth().authStateReady()
                  const currentUser = getAuth().currentUser
                  if (!currentUser) {
                    openRegisterNotification()
                    return
                  }
                  
                try {
                  setIsLoading(true)
                  const userSubDocRef = doc(getFirestore(), 'premium', currentUser.uid)
                  await updateDoc(userSubDocRef, { renews: !renews })
                  setCurrentPlan({ ...currentPlan, renews: !renews })
                  if (!renews) {
                    openWillRenewNotification()
                  } else {
                    openWarnNotification()
                  }
                } catch(e) {
                  console.log(e)
                  openErrorNotification()
                  return
                } finally {
                  setIsLoading(false)
                }
                }}>{ renews ? <>Cancel</> : <>Enable renew</>}</Button></div>}
              </div>}
          </div>
        </div>
      </Card>
    </Context.Provider>
  )
}

export default PremiumCard;
