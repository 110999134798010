import React, {useState, useRef} from 'react';
import { Modal, Tag, Input } from 'antd';

import {
  useRecoilValueLoadable,
} from 'recoil';

import { doc, setDoc, getFirestore } from "firebase/firestore";

import { currentUserState } from '../../atoms/atoms';

function Webhook({visible, setVisible, openNotification, openErrorNotification, setUserSettings, userSettings}) {
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)
  
  const [loading, setLoading] = useState(false)

  const webhookUrlRef = useRef(null)

  if (currentUserLoading !== 'hasValue') {
    return <></>
  }
 
  return (
    <Modal
      title="Configure Webhook"
      visible={visible}
      onOk={async () => {
        setLoading(true)
        try {
          const newUserSettings = {...userSettings, should_webhook_notify: true, webhook_url: webhookUrlRef.current.state.value}
          if (currentUser !== null) {
            const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
            await setDoc(userSubDocRef, {...userSettings, should_webhook_notify: true, webhook_url: webhookUrlRef.current.state.value})
          }
          openNotification()
          setUserSettings(newUserSettings)
        } catch(e) {
          console.log(e)
          openErrorNotification()
          return
        } finally {
          setLoading(false)
          setVisible(false)
        }
      }}
      onCancel={() => setVisible(false)}
      okButtonProps={{loading: loading}}
    >
      <div className="modal-content">
        Enter webhook URL:<Input ref={webhookUrlRef} />
      </div>
    </Modal>
  )
}

export default Webhook
