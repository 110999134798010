import React from 'react';
import { Modal, Tag } from 'antd';

import {
  useRecoilValueLoadable,
} from 'recoil';

import { currentUserState } from '../../atoms/atoms';

function Slack({visible, setVisible}) {
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  if (currentUserLoading !== 'hasValue') {
    return <></>
  }
 
  return (
    <Modal
      title="Slack Notifications"
      visible={visible}
      onOk={() => {
        window.open(`https://slack.me/stackalertbot?start=${currentUser.uid}`, "_blank")
        setVisible(false)
      }}
      onCancel={() => setVisible(false)}
    >
      <div className="modal-content">
        <p>
          <h4>Add StackAlert app to your Slack workspace</h4>
          <p>Need to be workspace admin.</p>
          <a href="https://slack.com/oauth/v2/authorize?client_id=7067678787142.7074377686066&scope=chat:write,team:read&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
        </p>
        <div style={{borderBottom: '1px solid #d0d0d0', marginBottom: '1rem', marginTop: '1.25rem'}} />
        <p>
          <h4>Connect to StackAlert app</h4>
          <p>Authorize StackAlert app to send you direct messages, <a onClick={() => window.open("https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.team&client_id=7067678787142.7074377686066", "_blank")}>click here</a>.</p>
        </p>
      </div>
    </Modal>
  )
}

export default Slack
