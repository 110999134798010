/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import {Avatar} from 'antd';
import App from './App';
import TechsMarquee from './components/TechsMarquee/TechsMarquee';

import whatsapp from './assets/images-mine/whatsapp.svg';
import telegram from './assets/images-mine/telegram.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers as faCircleCheckRegular } from '@fortawesome/free-solid-svg-icons';

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root'),
);

const FadeContent = ({ children }) => {
	const wrapper = useRef();

	useEffect(() => {
		animateFading(wrapper.current);
	}, []);

	//animate sliding on scroll
	const animateFading = (element) => {
		const fadeOnScrollOptions = {
			threshold: 0.3,
			rootMargin: '0px 0px -100px 0px',
		};

		const fadeOnScrollObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				} else {
					entry.target.classList.add('appear');
					observer.unobserve(entry.target);
				}
			});
		}, fadeOnScrollOptions);

		fadeOnScrollObserver.observe(element);
	};

	return (
		<div className="card fade-in" ref={wrapper}>
			{children}
		</div>
	);
};

try {
	setTimeout(() => {
		try {
			ReactDOM.render(
				<FadeContent><TechsMarquee /></FadeContent>,
				document.getElementById('my-marquee'),
			)
			ReactDOM.render(
				<FadeContent>
					<div className="mb-10 inline-flex flex-wrap justify-center w-full gap-8">
						<div className={`${''} rounded-full bg-white p-6 dark:bg-neutral-800 hover:bg-neutral-700 dark:shadow-black/20 shadow-xl`}>
							<div className="" style={{ fontFamily: 'system-ui' }}>
								<p className="text-sm font-light text-neutral-500 dark:text-neutral-300" style={{ lineHeight: '1.25rem' }}>
									<FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faCircleCheckRegular} />Trusted by 1,000+ users
								</p>
							</div>
						</div>
					</div>
				</FadeContent>,
				document.getElementById('trusted-by'),
			)
			ReactDOM.render(
				<img src={whatsapp} style={{transform: 'scale(0.5) translate(2px, 3px)'}} />,
				document.getElementById('land-whatsapp'),
			)
			ReactDOM.render(
				<img src={telegram} style={{width: '100%', transform: 'scale(0.55)'}} />,
				document.getElementById('land-tg'),
			)
		} catch {
		}
	}, 1)
} catch {
}

try {
	const data = [
		{
			quote: `With StackAlert I was the first to know that React has released v19 with the new compiler. I couldn't have known about it faster otherwise.`,
			name: 'Anton Makarenko',
			role: 'Frontend Lead @ Walmart',
			extraClassName: 'block',
		},
		{
			quote: `I got a notification about a new Kafka version with critical Log4j security fix. I could patch the version swiftly. This is awesome.`,
			name: 'Avi Shem-Tov',
			role: 'DevOps Lead @ Booking',
			extraClassName: 'block',
		},
		{
			quote: `I could incorporate the latest and most performant versions of AI models. Much better to get it this way with a push.`,
			name: 'Nadia Comaneci',
			role: 'AI Architect @ CrowdStrike',
			extraClassName: 'block',
		},
		{
			quote: `As an IT engineer, it definetly helps me do my job better.`,
			name: 'Jamie Ander',
			role: 'IT Engineer @ Tipalti',
			extraClassName: 'hidden sm:block',
		},
		{
			quote: `I love the visibility that it gives me over my stack.`,
			name: 'Moriah Davidi',
			role: 'SecOps @ Finastro',
			extraClassName: 'hidden sm:block',
		},
	]
	setTimeout(() => {
		try {
			ReactDOM.render(
				<div className="inline-flex flex-wrap justify-center w-full gap-8">
					{data.map(({quote, name, role,extraClassName}) => <FadeContent key={name}>
						<div className="max-w-96 md:max-w-72">
							<div
								className={`${extraClassName} rounded-lg bg-white p-6 dark:bg-neutral-800 dark:shadow-black/20 shadow-xl`}>
								<div className="md:flex md:flex-row">
									<div className="" style={{ fontFamily: 'system-ui' }}>
										<p
											className="mb-6 text-sm font-light text-neutral-500 dark:text-neutral-300" style={{ lineHeight: '1.25rem' }}>
											"{quote}"
										</p>
										<p
											className="mb-2 text-xl font-semibold text-neutral-800 dark:text-neutral-200">
											{name}
										</p>
										<p
											className="mb-0 font-semibold text-neutral-500 dark:text-neutral-400">
											{role}
										</p>
									</div>
								</div>
							</div>
						</div>
					</FadeContent>)}
				</div>,
				document.getElementById('land-testimonials'),
			)
		} catch {
		}
	}, 1)
} catch {
}

getAuth().authStateReady().then(() => {
	if (getAuth().currentUser) {
		const labels = document.getElementsByClassName('label')
		for (let i = 0; i < labels.length; i++) {
			if (labels[i].textContent === 'Get started for free') {
				labels[i].textContent = 'Take me in'
			}
		}
	}
})
