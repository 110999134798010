import React, { useState } from 'react';
import { Card, Popover } from 'antd';
import {useRecoilState} from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck as faCircleCheckRegular } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck as faCircleCheckSolid } from '@fortawesome/free-solid-svg-icons';

import TechCardPopover from './TechCardPopover'

import { userSubscriptionsState } from '../../atoms/atoms';

import angular from '../../assets/images-tech/angular.png';
import ansible from '../../assets/images-tech/ansible.png';
import bazel from '../../assets/images-tech/bazel.png';
import cassandra from '../../assets/images-tech/cassandra.png';
import containerd from '../../assets/images-tech/containerd.png';
import django from '../../assets/images-tech/django.png';
import docker from '../../assets/images-tech/docker.png';
import elasticsearch from '../../assets/images-tech/elasticsearch.png';
import envoy from '../../assets/images-tech/envoy.png';
import etcd from '../../assets/images-tech/etcd.png';
import flink from '../../assets/images-tech/flink.png';
import gatsby from '../../assets/images-tech/gatsby.png';
import golang from '../../assets/images-tech/golang.png';
import hadoop from '../../assets/images-tech/hadoop.png';
import haproxy from '../../assets/images-tech/haproxy.png';
import helm from '../../assets/images-tech/helm.png';
import ignite from '../../assets/images-tech/ignite.png';
import istio from '../../assets/images-tech/istio.png';
import java from '../../assets/images-tech/java.png';
import kafka from '../../assets/images-tech/kafka.png';
import kubernetes from '../../assets/images-tech/kubernetes.png';
import nomad from '../../assets/images-tech/nomad.png';
import marathon from '../../assets/images-tech/marathon.png';
import memcached from '../../assets/images-tech/memcached.png';
import mesos from '../../assets/images-tech/mesos.png';
import mongodb from '../../assets/images-tech/mongodb.png';
import nginx from '../../assets/images-tech/nginx.png';
import nodejs from '../../assets/images-tech/nodejs.png';
import python from '../../assets/images-tech/python.png';
import pytorch from '../../assets/images-tech/pytorch.png';
import rabbitmq from '../../assets/images-tech/rabbitmq.png';
import react from '../../assets/images-tech/react.png';
import scala from '../../assets/images-tech/scala.png';
import spark from '../../assets/images-tech/spark.png';
import storm from '../../assets/images-tech/storm.png';
import strapi from '../../assets/images-tech/strapi.png';
import terraform from '../../assets/images-tech/terraform.png';
import typescript from '../../assets/images-tech/typescript.png';
import vuejs from '../../assets/images-tech/vuejs.png';
import webpack from '../../assets/images-tech/webpack.png';
import zookeeper from '../../assets/images-tech/zookeeper.png';
import antd from '../../assets/images-tech/antd.png';
import npm from '../../assets/images-tech/npm.png';
import yarn from '../../assets/images-tech/yarn.png';
import vite from '../../assets/images-tech/vite.png';
import linkerd from '../../assets/images-tech/linkerd.png';
import podman from '../../assets/images-tech/podman.png';
import calico from '../../assets/images-tech/calico.png';
import crio from '../../assets/images-tech/crio.png';
import argo from '../../assets/images-tech/argo.png';
import prometheus from '../../assets/images-tech/prometheus.png';
import grafana from '../../assets/images-tech/grafana.png';
import chef from '../../assets/images-tech/chef.png';
import puppet from '../../assets/images-tech/puppet.png';
import pulumi from '../../assets/images-tech/pulumi.png';
import jenkins from '../../assets/images-tech/jenkins.png';
import saltstack from '../../assets/images-tech/saltstack.png';
import coreos from '../../assets/images-tech/coreos.png';
import babel from '../../assets/images-tech/babel.png';
import postgresql from '../../assets/images-tech/postgresql.png';
import mysql from '../../assets/images-tech/mysql.png';
import tensorflow from '../../assets/images-tech/tensorflow.png';
import fastapi from '../../assets/images-tech/fastapi.png';
import mariadb from '../../assets/images-tech/mariadb.png';
import neo4j from '../../assets/images-tech/neo4j.png';
import influxdb from '../../assets/images-tech/influxdb.png';
import solr from '../../assets/images-tech/solr.png';
import sqlserver from '../../assets/images-tech/sqlserver.png';
import pandas from '../../assets/images-tech/pandas.png';
import numpy from '../../assets/images-tech/numpy.png';
import scipy from '../../assets/images-tech/scipy.png';
import matplotlib from '../../assets/images-tech/matplotlib.png';
import opencv from '../../assets/images-tech/opencv.png';
import bokeh from '../../assets/images-tech/bokeh.png';
import logstash from '../../assets/images-tech/logstash.png';
import beats from '../../assets/images-tech/beats.png';
import fluentd from '../../assets/images-tech/fluentd.png';
import redis from '../../assets/images-tech/redis.png';
import grpc from '../../assets/images-tech/grpc.png';
import graphql from '../../assets/images-tech/graphql.png';
import cmake from '../../assets/images-tech/cmake.png';
import chrome from '../../assets/images-tech/chrome.png';
import firefox from '../../assets/images-tech/firefox.png';
import safari from '../../assets/images-tech/safari.png';
import plotly from '../../assets/images-tech/plotly.png';
import lens from '../../assets/images-tech/lens.png';
import dbeaver from '../../assets/images-tech/dbeaver.png';
import iterm2 from '../../assets/images-tech/iterm2.png';
import flannel from '../../assets/images-tech/flannel.png';
import jquery from '../../assets/images-tech/jquery.png';
import bootstrap from '../../assets/images-tech/bootstrap.png';
import svelte from '../../assets/images-tech/svelte.png';
import tailwind from '../../assets/images-tech/tailwind.png';
import vscode from '../../assets/images-tech/vscode.png';
import kong from '../../assets/images-tech/kong.png';
import android from '../../assets/images-tech/android.png';
import windows from '../../assets/images-tech/windows.png';
import linux from '../../assets/images-tech/linux.png';
import rust from '../../assets/images-tech/rust.png';
import cpp from '../../assets/images-tech/cpp.png';
import apple from '../../assets/images-tech/apple.png';
import tekton from '../../assets/images-tech/tekton.png';
import jenkinsx from '../../assets/images-tech/jenkinsx.png';
import nextjs from '../../assets/images-tech/nextjs.png';
import slack from '../../assets/images-tech/slack.png';
import flutter from '../../assets/images-tech/flutter.png';
import postman from '../../assets/images-tech/postman.png';
import gqlplayground from '../../assets/images-tech/gqlplayground.png';
import rook from '../../assets/images-tech/rook.png';
import vagrant from '../../assets/images-tech/vagrant.png';
import vault from '../../assets/images-tech/vault.png';
import ubuntu from '../../assets/images-tech/ubuntu.png';
import pycharm from '../../assets/images-tech/pycharm.png';
import goland from '../../assets/images-tech/goland.png';
import webstorm from '../../assets/images-tech/webstorm.png';
import datagrip from '../../assets/images-tech/datagrip.png';
import xcode from '../../assets/images-tech/xcode.png';
import airflow from '../../assets/images-tech/airflow.png';
import php from '../../assets/images-tech/php.png';
import kotlin from '../../assets/images-tech/kotlin.png';
import gvisor from '../../assets/images-tech/gvisor.png';
import cadvisor from '../../assets/images-tech/cadvisor.png';
import lxc from '../../assets/images-tech/lxc.png';
import git from '../../assets/images-tech/git.png';
import gradle from '../../assets/images-tech/gradle.png';
import k3s from '../../assets/images-tech/k3s.png';
import swift from '../../assets/images-tech/swift.png';
import protobuf from '../../assets/images-tech/protobuf.png';
import tikv from '../../assets/images-tech/tikv.png';
import spinnaker from '../../assets/images-tech/spinnaker.png';
import androidstudio from '../../assets/images-tech/androidstudio.png';
import cilium from '../../assets/images-tech/cilium.png';
import flux from '../../assets/images-tech/flux.png';
import k9s from '../../assets/images-tech/k9s.png';
import nats from '../../assets/images-tech/nats.png';
import jaeger from '../../assets/images-tech/jaeger.png';
import centos from '../../assets/images-tech/centos.png';
import freebsd from '../../assets/images-tech/freebsd.png';
import debian from '../../assets/images-tech/debian.png';
import fedora from '../../assets/images-tech/fedora.png';
import opensuse from '../../assets/images-tech/opensuse.png';
import electron from '../../assets/images-tech/electron.png';
import arch from '../../assets/images-tech/arch.png';
import arc from '../../assets/images-tech/arc.png';
import bcc from '../../assets/images-tech/bcc.png';
import virtualbox from '../../assets/images-tech/virtualbox.png';
import squid from '../../assets/images-tech/squid.png';
import dart from '../../assets/images-tech/dart.png';
import ruby from '../../assets/images-tech/ruby.png';
import curl from '../../assets/images-tech/curl.png';
import quic from '../../assets/images-tech/quic.png';
import bower from '../../assets/images-tech/bower.png';
import tls from '../../assets/images-tech/tls.png';
import http from '../../assets/images-tech/http.png';
import ember from '../../assets/images-tech/ember.png';
import sqlite from '../../assets/images-tech/sqlite.png';

const images = {
  angular, ansible, bazel, cassandra, containerd, django, docker, elasticsearch, envoy, etcd, flink, gatsby, golang, hadoop,
  haproxy, helm, ignite, istio, java, kafka, kubernetes, marathon, memcached, mesos, mongodb, nginx, nodejs, python, pytorch, rabbitmq,
  react, scala, spark, storm, strapi, terraform, typescript, vuejs, webpack, zookeeper, antd, yarn, npm, vite, linkerd, podman, calico, crio,
  argo, prometheus, grafana, chef, puppet, pulumi, jenkins, saltstack, coreos, babel, postgresql, mysql, tensorflow, fastapi, mariadb, neo4j, influxdb, solr, sqlserver, pandas, numpy, scipy, matplotlib, opencv,
  bokeh, logstash, beats, fluentd, redis, grpc, graphql, cmake, chrome, firefox, safari, plotly, lens, dbeaver, iterm2, flannel, jquery, bootstrap, svelte,
  tailwind, vscode, kong, android, windows, linux, rust, cpp, apple, tekton, jenkinsx, nextjs, slack, flutter,
  postman, gqlplayground, rook, vagrant, vault, ubuntu, pycharm, goland, webstorm, datagrip, xcode, airflow, php, kotlin, gvisor, cadvisor,
  lxc, git, gradle, k3s, swift, protobuf, tikv, spinnaker, androidstudio, cilium, flux, k9s, nats,
  jaeger, centos, freebsd, debian,fedora, opensuse, electron, arch, bcc, virtualbox, squid, dart, ruby, curl, quic, bower, nomad, tls, ember, sqlite,
  http, arc,
};

export const techRealNameToName = {
  macOS: 'apple',
  iOS: 'apple',
  'Next.js': 'nextjs',
  'C++': 'cpp',
  'GraphQL Playground': 'gqlplayground',
  'React Native': 'react',
  'cri-o': 'crio',
  'Protocol Buffers': 'protobuf',
  'SQL Server': 'sqlserver',
  'Android Studio': 'androidstudio',
  'Vue.js': 'vuejs',
  'Node.js': 'nodejs',
  'Linux Kernel': 'linux',
};

function camelCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
}

export const TechStatus = {
  NotActive: 0,
  PotentiallyActive: 1,
  Active: 2,
  PotentiallyInactive: 3,
};

function TechCard({ techName, techStatus, toggleChecked, openable }) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const [userSubscriptions, setUserSubscriptions] = useRecoilState(userSubscriptionsState)

  const isCardActive = techStatus === TechStatus.Active || techStatus === TechStatus.PotentiallyActive;

  const theCard = 
    <Card bordered className={`${window.innerWidth < 992 && "!shadow-md"} criclebox tablespace tech-card ${isCardActive ? 'tech-card-active' : ''} ${hovered && ''}`} onClick={() => toggleChecked()}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <div className="tech-card-badges-container">
        {techStatus === TechStatus.Active && <FontAwesomeIcon icon={faCircleCheckSolid} />}
        {[TechStatus.PotentiallyActive,TechStatus.PotentiallyInactive].includes(techStatus) && <FontAwesomeIcon icon={faCircleCheckRegular} />}
      </div>
      <div className="tech-card-inner">
        <div className="tech-image-container">
          <img src={images[camelCase((techRealNameToName?.[techName] || techName).toLowerCase())]} className="tech-image" alt={techName[0]} />
        </div>
        <div className="tech-content">{techName}</div>
      </div>
    </Card>
  
  return (
    openable ? <Popover content={<TechCardPopover onRemove={() => {
      // todo: remove from db if needed
      setUserSubscriptions(userSubscriptions.filter(({name}) => name !== techName))
    }} />} placement="bottom" trigger="click">{theCard}</Popover> : theCard
  );
}

// function TechCardDemo({ techName, techStatus, toggleChecked, openable }) {
//   // const isCardActive = techStatus === TechStatus.Active || techStatus === TechStatus.PotentiallyActive;

//   return (
//     <Card bordered className={`criclebox tech-card-active`} style={{display: 'inline-block', marginRight: 16, marginBottom: 16}}>
//       <div>
//         {/* <div className="tech-card-badges-container">
//           {techStatus === TechStatus.Active && <FontAwesomeIcon icon={faCircleCheckSolid} />}
//           {techStatus === TechStatus.PotentiallyActive && <FontAwesomeIcon icon={faCircleCheckRegular} />}
//         </div> */}
//         <div style={{display: 'flex', gap: 8, paddingLeft: 8, paddingRight: 8, cursor: 'default'}}>
//           <div className="tech-image-container">
//             <img src={images[camelCase((techRealNameToName?.[techName] || techName).toLowerCase())]} className="tech-image" alt={techName[0]} />
//           </div>
//           <div>{techName}</div>
//         </div>
//       </div>
//     </Card>
//   )

  // return (
  //   openable ? <Popover content={<TechCardPopover onRemove={() => {
  //     // todo: remove from db if needed
  //     setUserSubscriptions(userSubscriptions.filter((name) => name !== repoName))
  //   }} />} placement="bottom" trigger="click">{theCard}</Popover> : theCard
  // );
// }

export default TechCard;
