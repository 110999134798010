/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useRef, useEffect } from 'react'

import {
  Button,
  Input,
  notification,
} from 'antd';

import {
  useRecoilStateLoadable,
  useRecoilValueLoadable,
} from 'recoil';

import { getAuth } from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";

import { SearchOutlined } from '@ant-design/icons';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { TechStatus } from '../components/TechCard/TechCard';

import GithubRepoCard from '../components/GithubRepoCard/GithubRepoCard';

import { userGithubSubscriptionsState, currentPlanState } from '../atoms/atoms';

const Context = React.createContext({ name: 'Default' });

function Billing() {
  const [{ contents: userGithubSubscriptions, state: userGithubSubscriptionsLoading }, setUserGithubSubscriptions] = useRecoilStateLoadable(userGithubSubscriptionsState)

  const { contents: currentPlan, state: currentPlanLoading } = useRecoilValueLoadable(currentPlanState)

  const [isLoading, setIsLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification();

  const newRepo = useRef(null)

  const openNotification = () => {
    api.info({
      message: `Subscriptions saved`,
      description: <Context.Consumer>{({ name }) => `Selected technologies have been saved.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openWarnNotification = () => {
    api.warning({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Need to upgrade plan.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openErrorNotification = () => {
    api.error({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Failed to save.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openErrorNotificationNotExist = () => {
    api.error({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Repository doesn't exist on GitHub.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  // const [myStyle, setMyStyle] = useState({ opacity: 0, transform: 'scale(1.02)' })
  // useEffect(() => {
  //   if (currentPlanLoading !== 'hasValue') return
  //   setTimeout(() => setMyStyle({ opacity: 1, transform: 'scale(1)' }), 1)
  // }, [currentPlanLoading])

  const [myStyle2, setMyStyle2] = useState({ opacity: 0, transform: 'scale(1.02)' })
  useEffect(() => {
    if (userGithubSubscriptionsLoading !== 'hasValue') return
    setTimeout(() => setMyStyle2({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [userGithubSubscriptionsLoading])

  const [myStyle3, setMyStyle3] = useState({ opacity: 0, transform: 'scale(1.02)', height: '1rem' })
  useEffect(() => {
    if (userGithubSubscriptionsLoading !== 'hasValue') return
    setTimeout(() => setMyStyle3({ opacity: 1, transform: 'scale(1)', height: 'unset' }), 1)
  }, [userGithubSubscriptionsLoading])

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="following-repos-container">
        <div className="following-repos">Follow New Repository</div>
        {userGithubSubscriptionsLoading === 'loading' && <div><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>}
        <div className="following-repos-search-container" style={{...myStyle3, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
          <Input
            className="header-search header-search-mine"
            placeholder="Repository name or URL..."
            prefix={<SearchOutlined />}
            ref={newRepo}
          />
          <Button type="primary" loading={isLoading} onClick={async () => {
            setIsLoading(true)

            const newUserGithubSubscriptions = [...userGithubSubscriptions, newRepo.current.state.value]

            await getAuth().authStateReady()
            const currentUser = getAuth().currentUser
            if (!currentUser) {
              setUserGithubSubscriptions(newUserGithubSubscriptions)
              openNotification()
              newRepo.current.setValue('')
              setIsLoading(false)
              return
            }

            if ((!currentPlan?.plan || currentPlan.plan === 'Free') && newUserGithubSubscriptions.length > 1) {
              openWarnNotification()
              setIsLoading(false)
              return
            }

            try {
              const result = await fetch('https://api.github.com/repos/'+newRepo.current.state.value)
              if (result.status < 200 || result.status >= 300) {
                openErrorNotificationNotExist()
                return
              }
              const userSubDocRef = doc(getFirestore(), 'github_subs', currentUser.uid)
              await setDoc(userSubDocRef, {
                repos: newUserGithubSubscriptions
              })
              setUserGithubSubscriptions(newUserGithubSubscriptions)
              newRepo.current.setValue('')
            } catch(e) {
              console.log(e)
              openErrorNotification()
              return
            } finally {
              setIsLoading(false)
            }

            openNotification()
          }}><span className="tech-save-button"><span>Add</span></span></Button>
        </div>
      </div>
      <div className="following-repos">Repositories I'm Following</div>
        {userGithubSubscriptionsLoading === 'loading' && <div><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>}
        {userGithubSubscriptionsLoading === 'hasError' && <>Something went wrong</>}
      <div className="following-repos-real" style={{...myStyle2, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
        {userGithubSubscriptionsLoading === 'hasValue' && userGithubSubscriptions.length === 0 && <div style={{fontSize: 13}}>Nothing here at the moment.<br />Pleases add new repositories from above.</div>}
        {userGithubSubscriptionsLoading === 'hasValue' && userGithubSubscriptions.map((repo) => (
          <GithubRepoCard key={repo} repoName={repo} techStatus={TechStatus.Active} openable />
        ))}
      </div>
    </Context.Provider>
  );
}

export default Billing;
