import React, {useEffect} from 'react';
import { Modal, Tag } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import { currentUserState } from '../../atoms/atoms';

function DemoInitial({visible, setVisible}) {
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  useEffect(() => {
    if (currentUserLoading !== 'hasValue') return
    if (currentUser) return
    if (window.location.pathname  === '/') return
    setVisible(true)
  }, [currentUser, currentUserLoading, setVisible])

  return (
    <Modal
      title="Welcome to StackAlert Demo"
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
    >
      <div className="modal-content">
        <p>You can browse around and then sign up.</p>
      </div>
    </Modal>
  )
}

export default DemoInitial
