/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useRecoilValueLoadable } from 'recoil';
import { Menu, Button, Popover } from 'antd';
import { CoffeeOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTelegram, faWhatsapp, faXTwitter } from '@fortawesome/free-brands-svg-icons'

import github from '../../assets/images-mine/github.svg';
import marketplace from '../../assets/images-mine/marketplace.svg';
import lightning from '../../assets/images-mine/lightning.svg';
import { faMailForward } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import { getAuth } from "firebase/auth";

import { usetSettingsState, currentUserState } from '../../atoms/atoms';

function Sidenav({ setSigninVisible, setSignupVisible, setDrawerVisible, color }) {
  const { pathname } = useLocation();

  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  const page = pathname.replace('/', '');

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      />
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      />
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      />
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      key={0}
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <a href="/" style={{display: 'inline-block', color: 'inherit'}}>
          <img src={logo} alt="" />
          <span>StackAlert</span>
        </a>
      </div>
      <hr className="!mb-3" />
      {/* <div className="w-full text-center mb-4" style={{fontSize: 13}}><a className="my-href">Login</a> / <a className="my-href">Signup</a></div> */}
      <Menu theme="light" mode="inline">
        {/* <Menu.Item key="1">
          <NavLink to="/home">
            <span
              className="icon"
              style={{
                background: page === 'home' ? color : '',
              }}
            >
              {dashboard}
            </span>
            <span className="label">Home</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="10">
          <NavLink to="/stack" onClick={()=>setDrawerVisible(false)}>
            <span
              className="icon"
              style={{
                background: page === 'stack' ? color : '',
              }}
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path d="M1531 4145 c-1109 -554 -1046 -516 -1046 -625 0 -109 -63 -71 1046
              -625 808 -404 996 -495 1029 -495 32 0 218 89 1012 486 535 268 986 496 1002
              507 41 29 70 99 62 151 -15 88 22 67 -1047 601 -809 405 -996 495 -1029 495
              -33 0 -220 -90 -1029 -495z"
                  />
                  <path d="M731 2785 c-224 -112 -246 -132 -246 -225 0 -109 -63 -71 1046 -625
              808 -404 996 -495 1029 -495 32 0 218 89 1012 486 535 268 986 496 1002 507
              67 48 82 156 30 224 -35 46 -387 223 -444 223 -32 0 -190 -75 -820 -390 l-780
              -390 -780 390 c-630 315 -788 390 -820 390 -29 0 -86 -23 -229 -95z"
                  />
                  <path d="M731 1825 c-224 -112 -246 -132 -246 -225 0 -109 -63 -71 1046 -625
              809 -405 996 -495 1029 -495 33 0 220 90 1029 495 1109 554 1046 516 1046 625
              0 93 -22 113 -246 225 -143 72 -200 95 -229 95 -32 0 -190 -75 -820 -390
              l-780 -390 -780 390 c-630 315 -788 390 -820 390 -29 0 -86 -23 -229 -95z"
                  />
                </g>
              </svg>
            </span>
            <span className="label">My Stack</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2" onClick={()=>setDrawerVisible(false)}>
          <NavLink to="/marketplace">
            <span
              className="icon"
              style={{
                background: page === 'marketplace' ? color : '',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Capa_1" width="800px" height="20" viewBox="0 0 902.86 902.86" xmlSpace="preserve">
                <g>
                  <g>
                    <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z     M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z" />
                    <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717    c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744    c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742    C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744    c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z     M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742    S619.162,694.432,619.162,716.897z" />
                  </g>
                </g>
              </svg>
            </span>
            <span className="label">Marketplace</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3" onClick={()=>setDrawerVisible(false)}>
          <NavLink to="/github">
            <span
              className="icon"
              style={{
                background: page === 'github' ? color : '',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)" />
              </svg>
            </span>
            <span className="label">GitHub</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4" onClick={()=>setDrawerVisible(false)}>
          <NavLink to="/notifications">
            <span
              className="icon"
              style={{
                background: page === 'notifications' ? color : '',
              }}
            >
              {rtl}
            </span>
            <span className="label">Notifications</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          <div style={{ marginTop: 12 }}>Account</div>
        </Menu.Item>
        {/* <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === 'profile' ? color : '',
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}
        {currentUserLoading === 'hasValue' && currentUser === null && <>
          <Menu.Item key="7" onClick={() => setSigninVisible(true)}>
            <a>
              <span className="icon">{signin}</span>
              <span className="label">Login</span>
            </a>
          </Menu.Item>
          <Menu.Item key="8" onClick={() => setSignupVisible(true)}>
            {/* <NavLink to="/sign-up"> */}
            <a>
              <span className="icon">{signup}</span>
              <span className="label">Signup</span>
            </a>
            {/* </NavLink> */}
          </Menu.Item>
        </>}
        <Menu.Item key="9" onClick={()=>setDrawerVisible(false)}>
          <NavLink to="/plans">
            <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                <g><path d="M496.2,10L255.5,499.5h185.9L212.5,990l568.2-667.6H552.2L787.5,10H496.2z" /></g>
              </svg>
            </span>
            <span className="label">Plans</span>
          </NavLink>
        </Menu.Item>
        
        {/* <Menu.Item className="menu-item-header" key="10">
          <div style={{ marginTop: 12 }}>Info</div>
        </Menu.Item>
        <Menu.Item key="12" onClick={()=>setDrawerVisible(false)}>
          <NavLink to="/info">
            <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                <g><path d="M496.2,10L255.5,499.5h185.9L212.5,990l568.2-667.6H552.2L787.5,10H496.2z" /></g>
              </svg>
            </span>
            <span className="label">Info & FAQ</span>
          </NavLink>
        </Menu.Item> */}

      </Menu>
      
      <div style={{marginTop: '2.5rem'}}>
        <Popover placement={window.innerWidth < 992 ? "rightBottom" : "rightTop"} content={
          <ul style={{listStyleType: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
            <div style={{borderBottom: '1px solid #e0e0e0', paddingBottom: '0.5rem'}}><a href="https://www.facebook.com/sharer/sharer.php?u=https://stackalert.me"><li><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faFacebook} />Facebook</li></a></div>
            <div style={{borderBottom: '1px solid #e0e0e0', paddingBottom: '0.5rem'}}><a href="https://twitter.com/share?url=https://stackalert.me"><li><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faXTwitter} />X</li></a></div>
            <div style={{borderBottom: '1px solid #e0e0e0', paddingBottom: '0.5rem'}}><a href="whatsapp://send?text=https://stackalert.me"><li><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faWhatsapp} />WhatsApp</li></a></div>
            <div style={{borderBottom: '1px solid #e0e0e0', paddingBottom: '0.5rem'}}><a href="tg://msg_url?url=https://stackalert.me"><li><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faTelegram} />Telegram</li></a></div>
            <a href="mailto:?subject=Have you heard about StackAlert&amp;body=https://stackalert.me"><li><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faEnvelope} />Email</li></a>
          </ul>
        } trigger="click">
          <div className="share-button">
            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" height="16px" width="16px" version="1.1" id="Layer_1" viewBox="0 0 458.624 458.624" xmlSpace="preserve">
              <g>
                <g>
                  <path d="M339.588,314.529c-14.215,0-27.456,4.133-38.621,11.239l-112.682-78.67c1.809-6.315,2.798-12.976,2.798-19.871    c0-6.896-0.989-13.557-2.798-19.871l109.64-76.547c11.764,8.356,26.133,13.286,41.662,13.286c39.79,0,72.047-32.257,72.047-72.047    C411.634,32.258,379.378,0,339.588,0c-39.79,0-72.047,32.257-72.047,72.047c0,5.255,0.578,10.373,1.646,15.308l-112.424,78.491    c-10.974-6.759-23.892-10.666-37.727-10.666c-39.79,0-72.047,32.257-72.047,72.047s32.256,72.047,72.047,72.047    c13.834,0,26.753-3.907,37.727-10.666l113.292,79.097c-1.629,6.017-2.514,12.34-2.514,18.872c0,39.79,32.257,72.047,72.047,72.047    c39.79,0,72.047-32.257,72.047-72.047C411.635,346.787,379.378,314.529,339.588,314.529z"/>
                </g>
              </g>
            </svg> */}
            <ShareAltOutlined />
            <span style={{marginLeft: '0.4rem'}}>Share</span>
          </div>
        </Popover>
        <div className="share-button" style={{marginTop: '1rem'}}>
            <a href="https://buymeacoffee.com/boundingbeaver" style={{color:'inherit', display: 'flex', alignItems: 'center'}} target="_blank" rel="noreferrer">
              <CoffeeOutlined />
              <span style={{marginLeft: '0.4rem'}}>Buy me a coffee</span>
            </a>
        </div>
        {currentUserLoading === 'hasValue' && currentUser !== null && <div id="contact-us-button" className="share-button" style={{marginTop: '1rem'}}>
            <a href="mailto:contact@stackalert.me" style={{color:'inherit', display: 'flex', alignItems: 'center'}} target="_blank" rel="noreferrer">
              <SendOutlined />
              <span style={{marginLeft: '0.4rem'}}>Contact us</span>
            </a>
        </div>}
      </div>

      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

export default Sidenav;
