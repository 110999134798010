import React from 'react';
import { Modal, Tag } from 'antd';

import {
  useRecoilValueLoadable,
} from 'recoil';

import { currentUserState } from '../../atoms/atoms';

function Telegram({visible, setVisible}) {
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  if (currentUserLoading !== 'hasValue') {
    return <></>
  }
 
  return (
    <Modal
      title="Telegram Notifications"
      visible={visible}
      onOk={() => {
        window.open(`https://telegram.me/stackalertbot?start=${currentUser.uid}`, "_blank")
        setVisible(false)
      }}
      onCancel={() => setVisible(false)}
    >
      <div className="modal-content">
        <p>Telegram will open on the onchat screen with our bot, please press <Tag>START</Tag>button there to complete the registration.</p>
      </div>
    </Modal>
  )
}

export default Telegram
