// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCU6ePPzOdMNg7ak0bE875Mkru-Kh6f5Kc",
  authDomain: "softalert-57d1a.firebaseapp.com",
  databaseURL: "https://softalert-57d1a.firebaseio.com",
  projectId: "softalert-57d1a",
  storageBucket: "softalert-57d1a.appspot.com",
  messagingSenderId: "898906062871",
  appId: "1:898906062871:web:985bd7a943f6105ed45c52",
  measurementId: "G-KZ4B3TSWB3"
};

// Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const initializeFirebaseApp = () => {
    try {
      return initializeApp(firebaseConfig);
    } catch (err) {
      if (err.code !== "app/duplicate-app") {
        throw err;
      }
      console.log('askdhgakjshdkajshdkjahsdkjahs error', err)
    }
  };

  initializeFirebaseApp();

export const db = getFirestore(initializeFirebaseApp());

const analytics = getAnalytics(initializeFirebaseApp());
