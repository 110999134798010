/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState,useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  RecoilRoot,
} from 'recoil';
import { Layout } from 'antd';
import { getAuth } from "firebase/auth";

import Home from './pages/Home';
import Tables from './pages/Tables';
import Billing from './pages/Billing';
import Rtl from './pages/Rtl';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Premium from './pages/Premium';
import Stack from './pages/Stack';
import SignInModal from './components/Modals/SignIn';
import SignUpModal from './components/Modals/SignUp';
import DemoInitial from './components/Modals/DemoInitial';
import Main from './components/layout/Main';
import 'antd/dist/antd.css';
import './assets/styles/main.css';
import './assets/styles/responsive.css';
import SandboxBar from './components/SandboxBar/SandboxBar';

function App() {
  const [signinVisible, setSigninVisible] = useState(false)
  const [signupVisible, setSignupVisible] = useState(false)
  const [demoVisible, setDemoVisible] = useState(false)
  
  return (
    <RecoilRoot>
      <div className="App">
        <Switch>
          <Route 
          render={({ location }) => !['/'].includes(location.pathname)
          ? <SandboxBar setVisible={setSigninVisible} setSignupVisible={setSignupVisible} />
          : null
      }/>
        </Switch>
        <Switch>
            <Route exact path="/" component={Home} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-in" exact component={SignIn} />
          <Main setSigninVisible={setSigninVisible} setSignupVisible={setSignupVisible}>
            {/* <Route exact path="/Home" component={Home} /> */}
            <Route exact path="/stack" component={Stack} />
            <Route exact path="/marketplace" component={Tables} />
            <Route exact path="/github" component={Billing} />
            <Route exact path="/notifications" component={Rtl} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/plans"><Premium setSignupVisible={setSignupVisible} /></Route>
            {/* <Redirect from="*" to="/dashboard" /> */}
          </Main>
        </Switch>
        <SignInModal visible={signinVisible} setVisible={setSigninVisible} setSignupVisible={setSignupVisible} />
        <SignUpModal visible={signupVisible} setVisible={setSignupVisible} setSigninVisible={setSigninVisible} />
        {/* <DemoInitial visible={demoVisible} setVisible={setDemoVisible} /> */}
      </div>
    </RecoilRoot>
  );
}

export default App;
