import React,{useEffect,useState} from 'react';

import { getAuth } from "firebase/auth";

function SandboxBar({setVisible, setSignupVisible}) {
  const [isAuth, setIsAuth] = useState(false)
  useEffect(() => getAuth().authStateReady().then(() => setIsAuth(getAuth().currentUser === null)), [])
  return (
    isAuth &&
    <div className="top_green_bar">
        <span>You are in demo mode. Please <span className="resend_ver resend_ver_2" onClick={() => {setVisible(true); setSignupVisible(false);}}>login</span> or <span className="resend_ver resend_ver_2" onClick={() => {setVisible(false); setSignupVisible(true);}}>signup</span>.</span>
    </div>
  )
}

export default SandboxBar;
