import {
  atom,
  selector,
} from 'recoil';

import { groupBy, partition } from 'lodash'

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { collection, addDoc, getDocs, query, getDoc, doc, getFirestore } from "firebase/firestore";

import {db,initializeFirebaseApp} from '../utils/firebase'

export const groupingState = atom({
  key: 'groupingState',
  default: true,
});

export const textFilterState = atom({
  key: 'textFilterState',
  default: '',
});

export const selectedPageState = atom({
  key: 'selectedPageState',
  default: '',
});

export const currentUserState = atom({
  key: 'currentUserState',
  default: selector({
    key: 'currentUserStateDefaultSelector',
    get: async ({get}) => {
      await getAuth().authStateReady()
      return JSON.parse(JSON.stringify(getAuth().currentUser))
    }
  }),
})

export const registeredTechnologiesState = atom({
  key: 'registeredTechnologiesState',
  default: ['React', 'TypeScript', 'Golang', 'Kafka', 'PostgreSQL', 'Prometheus', 'Elasticsearch', 'Lens', 'Kubernetes', 'macOS'],
  // default: selector({
  //   key: 'registeredTechnologiesStateDefault',
  //   get: ({get}) => {
  //     const mydoc = doc(db, `/subs/${get(currentUserState).uid}`)
  //     getDoc(mydoc).then((ds) => console.log('success', ds.data()))
  //   },
  // })
});

export const userSubscriptionsState = atom({
  key: 'userSubscriptionsState',
  default: selector({
    key: 'userSubscriptionsStateDefaultSelector',
    get: async ({get}) => {
      try {
        await getAuth().authStateReady()
        const currentUser = getAuth().currentUser
        if (!currentUser) {
          // return ['React', 'TypeScript', 'Golang', 'Kafka', 'PostgreSQL', 'Prometheus', 'Elasticsearch', 'Lens', 'Kubernetes', 'macOS'].map((x) => ({id:x, name:x}))
          // return [{id: 'jMFgXrtUt2u74ElHGU3D', name: 'MySQL'}]
          return [
            {
                "id": "pl4hMtMaUJMTWLeeOk4S",
                "name": "Lens",
                "group": "Desktop Applications"
            },
            {
                "id": "phIJSwoePSCxC3eeaslt",
                "name": "Kafka",
                "group": "Backend & Data Processing"
            },
            {
                "id": "AA2n8zozKsgZk8Chkz5w",
                "name": "Golang",
                "group": "Programming Languages"
            },
            {
                "id": "coupp4XsQoB7r700Z4Yb",
                "name": "TypeScript",
                "group": "Programming Languages"
            },
            {
                "id": "HzQ3hcrNRDcvQ4c5Uh0o",
                "name": "Prometheus",
                "group": "DevOps"
            },
            {
                "id": "OPM8yxL1j0sRDEczPRBI",
                "name": "React",
                "group": "Frontend"
            },
            {
                "id": "S3Oo8bRyZ8zU1blRqOlD",
                "name": "Elasticsearch",
                "group": "Databases & Cache"
            },
            {
                "id": "iP9cNP9m1vjx1moyjtOh",
                "name": "PostgreSQL",
                "group": "Databases & Cache"
            },
            {
                "id": "8SmdrnIP2o9tmkjgvIZT",
                "name": "Kubernetes",
                "group": "Kubernetes"
            },
            {
                "id": "A3mGNOUkyWbhizzF1JfS",
                "name": "macOS",
                "group": "Operating Systems"
            }
        ]
        }
        // console.log(get(currentUserState).uid === 'vLBelxE8i2aGC16oQz9PCgqd5pb2')
        // const mydoc = doc(getFirestore(), 'subs_new', get(currentUserState).uid)
        // const mydoc = doc(getFirestore(), 'subs_new', 'vLBelxE8i2aGC16oQz9PCgqd5pb2')
        const mydoc = doc(getFirestore(), 'subs_v2', currentUser.uid)
        // const mydoc = doc(getFirestore(), 'techs', '0aceeKKeDANXdqrHrSRu')
        const ds = await getDoc(mydoc)
        if (!ds.exists()) {
          return []
        }
        const data = ds.data()
        // console.log(data)
        // console.log(getDocs())
        const techs = await Promise.all(data.techs.map((dr) => getDoc(dr)))
        return techs.map((x) => ({id: x.id, name: x.data().name}))
      } catch(e) {
        console.log(e)
        throw e
      }
    },
  })
});

export const userGithubSubscriptionsState = atom({
  key: 'userGithubSubscriptionsState',
  default: selector({
    key: 'userGithubSubscriptionsStateDefaultSelector',
    get: async ({get}) => {
      try {
        await getAuth().authStateReady()
        const currentUser = getAuth().currentUser
        if (!currentUser) {
          return ['prettier/prettier-eslint', 'sindresorhus/awesome']
        }
        const mydoc = doc(getFirestore(), 'github_subs', currentUser.uid)
        const ds = await getDoc(mydoc)
        if (!ds.exists()) {
          return []
        }
        return ds.data().repos
      } catch(e) {
        console.log(e)
        throw e
      }
    },
  })
});

export const techsState = atom({
  key: 'techsState',
  default: selector({
    key: 'techsStateDefaultSelector',
    get: async ({get}) => {
      try {
        const qs = await getDocs(collection(db, "techs_v2"))
        return groupBy(qs.docs.map((qds) => {
            const data = qds.data()
            return {id: qds.id, name: data.name, group: data.group}
          }), 'group')
      } catch(e) {
        console.log(e)
        throw e
      }
    },
  })
});

export const usetSettingsState = atom({
  key: 'usetSettingsState',
  default: selector({
    key: 'usetSettingsDefaultSelector',
    get: async ({get}) => {
      try {
        await getAuth().authStateReady()
        const currentUser = getAuth().currentUser
        if (!currentUser) {
          return {should_send_tg: true, tg_username: '@johndoe', email: 'johndoe@gmail.com', webhook_url: 'http://webhook-site.com'}
        }
        const mydoc = doc(getFirestore(), 'user_settings', currentUser.uid)
        const ds = await getDoc(mydoc)
        return ds.data()
      } catch(e) {
        console.log(e)
        throw e
      }
    },
  })
});

export const registeredGithubReposState = atom({
  key: 'registeredGithubReposState',
  default: ['prettier/prettier-eslint', 'sindresorhus/awesome'],
});

export const currentPlanState = atom({
  key: 'currentPlanState',
  default: selector({
    key: 'currentPlanDefaultSelector',
    get: async ({get}) => {
      try {
        await getAuth().authStateReady()
        const currentUser = getAuth().currentUser
        if (!currentUser) {
          const oneYearFromNoew = new Date()
          oneYearFromNoew.setDate(oneYearFromNoew.getDate() + 365)
          return { plan: 'Free', expiry: oneYearFromNoew, renews: true }
        }
        const mydoc = doc(getFirestore(), 'premium', currentUser.uid)
        const ds = await getDoc(mydoc)
        if (!ds.exists()) {
          return { plan: 'Free' }
        }
        return ds.data()
      } catch(e) {
        console.log(e)
        throw e
      }
    },
  })
});
