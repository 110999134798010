/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useMemo, useEffect } from 'react';
import {
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  notification,
} from 'antd';

import { doc, setDoc, getFirestore } from "firebase/firestore";

import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import { sortBy } from 'lodash';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

// import { motion } from "framer-motion"

import { techsState, userSubscriptionsState, currentPlanState } from '../atoms/atoms';

import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';

import { getAuth } from "firebase/auth";

import TechCard, { TechStatus } from '../components/TechCard/TechCard';

import { groupingState, textFilterState } from '../atoms/atoms';

const Context = React.createContext({ name: 'Default' });

export function TechsGroup({ techs, techStatuses, setTechStatuses, initialTechStatuses, showHeader, techGroupName, clickable, openable }) {
  const textFilter = useRecoilValue(textFilterState);
  return (
    <div id="tech-group-2">
      { showHeader && <div className="tech-section-header">{techGroupName}</div> }
      <div id="tech-group">
        {sortBy(techs.filter((tech) => tech.name.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase())), (t) => t.name.toLowerCase()).map((tech) => (
          <TechCard
            openable={openable}
            key={tech.id}
            techName={tech.name}
            techStatus={techStatuses?.[tech.id] || TechStatus.NotActive}
            toggleChecked={() => clickable && setTechStatuses({ ...techStatuses, ...{
              [tech.id]: ![TechStatus.PotentiallyActive,TechStatus.Active].includes(techStatuses?.[tech.id]) ? (
                initialTechStatuses?.[tech.id] === TechStatus.Active ? TechStatus.Active : TechStatus.PotentiallyActive
              ) : (
                initialTechStatuses?.[tech.id] === TechStatus.Active ? TechStatus.PotentiallyInactive : TechStatus.NotActive
              )
            }})}
          />
        ))}
      </div>
    </div>
  );
}

function Tables() {
  const [grouping, setGrouping] = useRecoilState(groupingState);
  const [textFilter, setTextFilter] = useRecoilState(textFilterState);
  
  const { contents: userSubscriptions, state: userSubscriptionsLoading } = useRecoilValueLoadable(userSubscriptionsState)
  const setUserSubscriptions = useSetRecoilState(userSubscriptionsState)
  const { contents:currentPlan, state: currentPlanLoading } = useRecoilValueLoadable(currentPlanState);

  const { contents: techs, state: techsLoading } = useRecoilValueLoadable(techsState)
  
  const initialTechStatuses = useMemo(() => {
    if (userSubscriptionsLoading !== 'hasValue') return []
    return Object.fromEntries(userSubscriptions.map((x) => [x.id, TechStatus.Active]))
  }, [userSubscriptions, userSubscriptionsLoading])

  const [techStatuses, setTechStatuses] = useState({});

  const [isLoading, setIsLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (userSubscriptionsLoading !== 'hasValue') return
    setTechStatuses(Object.fromEntries(userSubscriptions.map((x) => [x.id, TechStatus.Active])))
  }, [userSubscriptions, userSubscriptionsLoading])

  const openNotification = () => {
    api.info({
      message: `Subscriptions saved`,
      description: <Context.Consumer>{({ name }) => `Selected technologies have been saved.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openErrorNotification = () => {
    api.error({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Failed to save.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const openWarnNotification = () => {
    api.warning({
      message: `Subscriptions weren't saved`,
      description: <Context.Consumer>{({ name }) => `Need to upgrade plan.`}</Context.Consumer>,
      placement: 'bottomRight',
    });
  };

  const saveSubscriptions = async () => {
    setIsLoading(true)

    const newlyActiveTechIDs = Object.entries(techStatuses).filter(([tech, status]) => [TechStatus.Active, TechStatus.PotentiallyActive].includes(status)).map(([id,status])=>id)
    
    const newlyActiveTechs = Object.values(techs).flatMap((x)=>x).filter((x)=>newlyActiveTechIDs.includes(x.id))

    await getAuth().authStateReady()
    const currentUser = getAuth().currentUser
    if (!currentUser) {
      setUserSubscriptions(newlyActiveTechs)
      openNotification()
      setIsLoading(false)
      return
    }

    if ((!currentPlan?.plan || currentPlan.plan === 'Free') && newlyActiveTechs.length > 1) {
      openWarnNotification()
      setIsLoading(false)
      return
    }

    try {
      const userSubDocRef = doc(getFirestore(), 'subs_v2', currentUser.uid)
      await setDoc(userSubDocRef, {
        techs: newlyActiveTechs.map((tech) => doc(getFirestore(), 'techs_v2', tech.id))
      })
      setUserSubscriptions(newlyActiveTechs)
    } catch(e) {
      console.log(e)
      openErrorNotification()
      return
    } finally {
      setIsLoading(false)
    }

    openNotification()
  }

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  const [myStyle, setMyStyle] = useState({ opacity: 0, transform: 'scale(1.01)' })
  const [myStyle2, setMyStyle2] = useState({ opacity: 0, transform: 'scale(1.01)' })

  useEffect(() => {
    if (currentPlanLoading !== 'hasValue') return
    setTimeout(() => setMyStyle({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [currentPlanLoading])

  useEffect(() => {
    if (userSubscriptionsLoading !== 'hasValue' || techsLoading !== 'hasValue') return
    setTimeout(() => setMyStyle2({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [userSubscriptionsLoading, techsLoading])

  return (
    <Context.Provider value={contextValue}>
      <div>
        {contextHolder}
        <div className="header-control-mine-2" style={{...myStyle, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
          <div className="header-control-mine">
            <Input
              className="header-search header-search-mine"
              placeholder="Type here..."
              prefix={<SearchOutlined />}
              onChange={(e) => setTextFilter(e.target.value)}
            />
            <Button type="primary" onClick={() => saveSubscriptions()} loading={isLoading}><span className="tech-save-button"><span>Follow ({Object.entries(techStatuses).filter(([tech, status]) => [TechStatus.Active, TechStatus.PotentiallyActive].includes(status)).length})</span></span></Button>
            <Button onClick={() => setGrouping(!grouping)} className="group-button">Group</Button>
          </div>
        </div>
          {(userSubscriptionsLoading === 'hasError' || techsLoading === 'hasError') && <>Something went wrong</>}
          {(userSubscriptionsLoading === 'loading' || techsLoading === 'loading') && <div style={{textAlign: 'center', marginTop: '2rem'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>}
          {(userSubscriptionsLoading === 'hasValue' && techsLoading === 'hasValue') &&
          <div style={{...myStyle2, transition: 'opacity 0.3s ease, transform 0.3s ease'}}>
            <div className="tabled">
              {
                grouping
                  ? Object.entries(techs).sort().map(([techsTitle, techsOfGroup]) => {
                    const filteredTechNames = techsOfGroup.filter((tech) => tech.name.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()));
                    return (
                      filteredTechNames.length > 0 && <TechsGroup techs={techsOfGroup} techStatuses={techStatuses} setTechStatuses={setTechStatuses} key={techsTitle} initialTechStatuses={initialTechStatuses} showHeader techGroupName={techsTitle} clickable />
                    );
                  })
                  : <TechsGroup techs={Object.values(techs).flatMap((x) => x)} techStatuses={techStatuses} setTechStatuses={setTechStatuses} initialTechStatuses={initialTechStatuses} clickable />
              }
            </div>
          </div>
          }
      </div>
    </Context.Provider>
  );
}

export default Tables;
