/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, {useState,useEffect,useCallback} from 'react';
import {
  useRecoilValueLoadable,
  useRecoilStateLoadable,
} from 'recoil';
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Tooltip,
  notification,
} from 'antd';

import { collection, addDoc, getDocs, getDoc, updateDoc, doc, setDoc, getFirestore } from "firebase/firestore";

// import firebase from '@firebase/app';
import { getMessaging,getToken, } from '@firebase/messaging';

import Telegram from '../components/Modals/Telegram'
import Webhook from '../components/Modals/Webhook'
import Slack from '../components/Modals/Slack'

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { ToTopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { usetSettingsState, currentUserState } from '../atoms/atoms';

// Images
import ava3 from '../assets/images/logo-slack.svg';
import tg from '../assets/images-mine/telegram.png';
import chrome from '../assets/images-tech/chrome.png';
import email from '../assets/images-mine/email.png';
import webhook from '../assets/images-mine/webhook.png';

const { Title } = Typography;

const Context = React.createContext({ name: 'Default' });

// project table start
const project = [
  {
    title: 'Channel',
    dataIndex: 'name',
    // width: "32%",
  },
  {
    title: 'Status',
    dataIndex: 'address',
  },
];

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
}

function Tables() {
  const [isWebhookModalOpen, setIsWebhookModalOpen] = useState(false);
  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
  const [isSlackModalOpen, setIsSlackModalOpen] = useState(false);

  const [{ contents: userSettings, state: userSettingsLoading }, setUserSettings] = useRecoilStateLoadable(usetSettingsState)
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  const [emailActivateLoading, setEmailActivateLoading] = useState(false)
  const [telegramActivateLoading, setTelegramActivateLoading] = useState(false)
  const [browserPushActivateLoading, setBrowserPushActivateLoading] = useState(false)
  const [slackActivateLoading, setSlackActivateLoading] = useState(false)
  const [webhookActivateLoading, setWebhookActivateLoading] = useState(false)

  const [slackCode, setSlackCode] = useState(getQueryVariable('code'));
  
  const [myStyle, setMyStyle] = useState({ opacity: 0, transform: 'scale(1.01)' })

  useEffect(() => {
    if (userSettingsLoading !== 'hasValue' || currentUserLoading !== 'hasValue') return
    setMyStyle({ opacity: 1, transform: 'scale(1)' })
  }, [userSettingsLoading, currentUserLoading])

  const [api, contextHolder] = notification.useNotification();

  const openNotification = useCallback(() => {
    api.info({
      message: `Notification channel saved`,
      description: <>Channel was successfully configured.</>,
      placement: 'bottomRight',
    });
  }, [api])

  const openErrorNotification = useCallback(() => {
    api.error({
      message: `Settings weren't saved`,
      description: <>Failed to save settings.</>,
      placement: 'bottomRight',
    });
  }, [api])

  const [isSetting, setIsSetting] = useState(false)

  useEffect(() => {
    if (slackCode) {
      const newUserSettings = {...userSettings, should_slack_notify: true}
      if (currentUser?.uid) {
        if (isSetting) return
        setIsSetting(true)
        const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
        setDoc(userSubDocRef, {...userSettings, should_slack_notify: true, slack_code: slackCode}).then(() => {
          openNotification()
          setUserSettings(newUserSettings)
        }).catch((e) => {
          console.log(e)
          openErrorNotification()
        }).finally(() => {
          setSlackActivateLoading(false)
          setSlackCode(undefined)
        })
      }
    }
  }, [userSettings, currentUser, openErrorNotification, openNotification, slackCode, setUserSettings, isSetting])
  
  const data = userSettingsLoading === 'hasValue' ? [
    {
      key: '1',
      name: (
        <Avatar.Group>
          <Avatar className="shape-avatar" src={email} size={25} alt="" shape="square" />
          <div className="avatar-info">
            <Title level={5}>Email</Title>
          </div>
        </Avatar.Group>
      ),
      address: userSettings?.should_send_emails ? (
        <div>
          <span className="integrated-checkmark">
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
          <span className="integrated-text">Active {currentUser !== null && `(${currentUser.email})`}{currentUser === null && `(${userSettings?.email})`} <Button loading={emailActivateLoading} style={{marginLeft: '0.5rem'}} danger onClick={async () => {
            try {
              const newUserSettings = {...userSettings, should_send_emails: false}
              if (currentUser !== null) {
              const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
                setEmailActivateLoading(true)
                await setDoc(userSubDocRef, {...userSettings, should_send_emails: false})
              }
              setUserSettings(newUserSettings)
              openNotification()
            } catch(e) {
              console.log(e)
              openErrorNotification()
              return
            } finally {
              setEmailActivateLoading(false)
            }
          }}>Deactivate</Button></span>
        </div>
      ) : <Button loading={emailActivateLoading}  onClick={async () => {
        try {
          const newUserSettings = {...userSettings, should_send_emails: true}
          if (currentUser !== null) {
            const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
            setEmailActivateLoading(true)
            await setDoc(userSubDocRef, {...userSettings, should_send_emails: true})
          }
          setUserSettings(newUserSettings)
          openNotification()
        } catch(e) {
          console.log(e)
          openErrorNotification()
          return
        } finally {
          setEmailActivateLoading(false)
        }
      }}>Activate</Button>,
    },
    {
      key: '2',
      name: (
        <Avatar.Group>
          <Avatar className="shape-avatar" src={tg} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Telegram</Title>
          </div>
        </Avatar.Group>
      ),
      address: userSettings?.should_send_tg ? (
        <div>
          <span className="integrated-checkmark">
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
          <span className="integrated-text">Active {userSettings.tg_username && `(${userSettings.tg_username})`} <Button loading={telegramActivateLoading} style={{marginLeft: '0.5rem'}} danger onClick={async () => {
            try {
              const newUserSettings = {...userSettings, should_send_tg: false}
              setTelegramActivateLoading(true)
              if (currentUser !== null) {
                const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
                await setDoc(userSubDocRef, {...userSettings, should_send_tg: false})
              }
              setUserSettings(newUserSettings)
              openNotification()
            } catch(e) {
              console.log(e)
              openErrorNotification()
              return
            } finally {
              setTelegramActivateLoading(false)
            }
          }}>Deactivate</Button></span>
        </div>
      ) : <Button onClick={() => {
        if (currentUser !== null) {
          setIsTelegramModalOpen(true)
        } else {
          setUserSettings({...userSettings, should_send_tg: true})
          openNotification()
        }
      }}>Activate</Button>,
    },
    {
      key: '3',
      name: (
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava3} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Slack</Title>
          </div>
        </Avatar.Group>
      ),
      address: userSettings?.should_slack_notify ? (
        <div>
          <span className="integrated-checkmark">
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
          <span className="integrated-text">Active<Button loading={slackActivateLoading} style={{marginLeft: '0.5rem'}} danger onClick={async () => {
              setSlackActivateLoading(true)
              try {
                const newUserSettings = {...userSettings, should_slack_notify: false}
                if (currentUser !== null) {
                  const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
                  await setDoc(userSubDocRef, {...userSettings, should_slack_notify: false})
                }
                openNotification()
                setUserSettings(newUserSettings)
              } catch(e) {
                console.log(e)
                openErrorNotification()
                return
              } finally {
                setSlackActivateLoading(false)
              }
          }}>Deactivate</Button></span>
        </div>
      ) : <Button loading={slackCode} onClick={async () => {
        if (currentUser !== null) {
          setIsSlackModalOpen(true)
        } else {
          setUserSettings({...userSettings, should_slack_notify: true})
          openNotification()
        }
      }}>Activate</Button>,
    },
    {
      key: '4',
      name: (
        <Avatar.Group>
          <Avatar className="shape-avatar" src={chrome} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Browser Notifications</Title>
          </div>
        </Avatar.Group>
      ),
      address: userSettings?.should_web_notify ? (
        <div>
          <span className="integrated-checkmark">
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
          <span className="integrated-text">Active<Button loading={browserPushActivateLoading} style={{marginLeft: '0.5rem'}} danger onClick={async () => {
              setBrowserPushActivateLoading(true)
              try {
                const newUserSettings = {...userSettings, should_web_notify: false}
                if (currentUser !== null) {
                  const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
                  await setDoc(userSubDocRef, {...userSettings, should_web_notify: false, web_notifications_token: ''})
                }
                openNotification()
                setUserSettings(newUserSettings)
              } catch(e) {
                console.log(e)
                openErrorNotification()
                return
              } finally {
                setBrowserPushActivateLoading(false)
              }
          }}>Deactivate</Button></span>
        </div>
      ) : <Button loading={browserPushActivateLoading} onClick={() => {
        if (currentUser === null) {
          setUserSettings({...userSettings, should_web_notify: true})
          openNotification()
          return
        }
        setBrowserPushActivateLoading(true)
        Notification.requestPermission().then(() => {
            getToken(getMessaging(), 'BMYtjzcnM-SDbXPJ_O7DokmCrEjK0WOPiPJGsJxnWe0gkn5kH42lQ7lQ-m0hwUtJJyPN-Zmjxyg6ky1YzRubXAU').then(async (token) => {
              try {
                const newUserSettings = {...userSettings, should_web_notify: true}
                if (currentUser !== null) {
                  const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
                  await setDoc(userSubDocRef, {...userSettings, should_web_notify: true, web_notifications_token: token})
                }
                setUserSettings(newUserSettings)
              } catch(e) {
                console.log(e)
                openErrorNotification()
                return
              } finally {
                setBrowserPushActivateLoading(false)
              }
            }).finally(() => setBrowserPushActivateLoading(false))
          }).finally(() => setBrowserPushActivateLoading(false))
        }}>Activate</Button>,
    },
    // {
    //   key: '5',
    //   name: (
    //     <Avatar.Group>
    //       <Avatar className="shape-avatar" src={webhook} size={25} alt="" shape="square" />
    //       <div className="avatar-info">
    //         <Title level={5}>Webhook</Title>
    //       </div>
    //     </Avatar.Group>
    //   ),
    //   address: userSettings?.should_webhook_notify ? (
    //     <div>
    //       <span className="integrated-checkmark">
    //         <FontAwesomeIcon icon={faCircleCheck} />
    //       </span>
    //       <span className="integrated-text">Active{userSettings?.webhook_url && ` (${(new URL(userSettings.webhook_url)).hostname})`}<Button loading={webhookActivateLoading} style={{marginLeft: '0.5rem'}} danger onClick={async () => {
    //           setWebhookActivateLoading(true)
    //           try {
    //             const newUserSettings = {...userSettings, should_webhook_notify: false}
    //             if (currentUser !== null) {
    //               const userSubDocRef = doc(getFirestore(), 'user_settings', currentUser.uid)
    //               await setDoc(userSubDocRef, {...userSettings, should_webhook_notify: false})
    //             }
    //             openNotification()
    //             setUserSettings(newUserSettings)
    //           } catch(e) {
    //             console.log(e)
    //             openErrorNotification()
    //             return
    //           } finally {
    //             setWebhookActivateLoading(false)
    //           }
    //       }}>Deactivate</Button></span>
    //     </div>
    //   ) : <Button onClick={() => {
    //     if (currentUser !== null) {
    //       setIsWebhookModalOpen(true)
    //     } else {
    //       setUserSettings({...userSettings, should_webhook_notify: true})
    //       openNotification()
    //     }
    //   }}>Activate</Button>,
    // },
  ]: []

  // if (userSettingsLoading === 'loading' || currentUserLoading === 'loading')
  //  return <div style={{textAlign: 'center', marginTop: '2rem'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>

  return (
    <Context.Provider value={''}>
      <Telegram visible={isTelegramModalOpen} setVisible={setIsTelegramModalOpen} />
      <Slack visible={isSlackModalOpen} setVisible={setIsSlackModalOpen} />
      <Webhook visible={isWebhookModalOpen} setVisible={setIsWebhookModalOpen} openNotification={openNotification} openErrorNotification={openErrorNotification} setUserSettings={setUserSettings} userSettings={userSettings} />
      {contextHolder}
      <div>
        {(userSettingsLoading === 'loading' || currentUserLoading === 'loading') && <div style={{textAlign: 'center', marginTop: '2rem'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>}
        <div className="tabled notifications-channels" style={{...myStyle, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Channels"
              >
                <div className="table-responsive">
                  <Table
                    columns={project}
                    dataSource={data}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Context.Provider>
  );
}

export default Tables;
