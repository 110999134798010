import React, {useState,useEffect} from 'react';

import {
  useRecoilValueLoadable,
} from 'recoil';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { currentPlanState, currentUserState } from '../atoms/atoms';

import PremiumCard from '../components/PremiumCard/PremiumCard';

function Premium({setSignupVisible}) {
  const { contents: currentPlan, state: currentPlanLoading } = useRecoilValueLoadable(currentPlanState)
  const { contents: currentUser, state: currentUserLoading } = useRecoilValueLoadable(currentUserState)

  const [myStyle, setMyStyle] = useState({ opacity: 0, transform: 'scale(1.01)' })
  useEffect(() => {
    if (currentPlanLoading !== 'hasValue' || currentUserLoading !== 'hasValue') return
    setTimeout(() => setMyStyle({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [currentPlanLoading, currentUserLoading])

  const basicTrial = currentPlan.plan === 'Free' ? ['7 days free trial'] : []
  const fullsuiteTrial = (currentPlan.plan === 'Free' || currentPlan.plan === 'Basic') ? ['7 days free trial'] : []

  return (
    <div>
      {(currentPlanLoading === 'loading' || currentUserLoading === 'loading') && <div style={{textAlign: 'center', marginTop: '2rem'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>}
      <div className='premium-container' style={{...myStyle, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
        <div className='premium-container-2' style={{...myStyle, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
          <PremiumCard tier="Free" price={0} features={['2 Technologies', 'Unlimited Notification Channels']} isSelected={currentPlan.plan === 'Free'} setSignupVisible={setSignupVisible} isLoggedIn={currentUser !== null}/>
          <PremiumCard tier="Basic" price={2} features={['10 Technologies', 'Unlimited Notification Channels', 'Instant Notifications', ...basicTrial]} isSelected={currentPlan?.plan === 'Basic'} upgradable={currentPlan.plan === 'Free'} cancelable renews={currentPlan.renews} setSignupVisible={setSignupVisible} isLoggedIn={currentUser !== null}/>
          <PremiumCard tier="Full Suite" price={3} features={['30 Technologies', 'Unlimited Notification Channels', 'Instant Notifications', ...fullsuiteTrial]} isSelected={currentPlan?.plan === 'Full Suite'} upgradable={currentPlan.plan === 'Free' || currentPlan.plan === 'Basic'} cancelable renews={currentPlan.renews} setSignupVisible={setSignupVisible} isLoggedIn={currentUser !== null}/>
        </div>
      </div>
    </div>
  )
}

export default Premium;
