import React, { useState,useEffect } from 'react';
import { Button, Modal } from 'antd';
import { Checkbox, Form, Input } from 'antd';
import { getApp } from "firebase/app";
import {initializeFirebaseApp} from '../../utils/firebase'

import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function SignIn({visible, setVisible, setSignupVisible}) {
  const [loading, setLoading] = useState(false)
  const [userValid, setUserValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(true)
  const [actionError, setActionError] = useState('')

  const [form] = Form.useForm();
  useEffect(() => initializeFirebaseApp(), [])
  
  return (
    <Modal
      title="Login"
      visible={visible}
      onOk={() => {
        const userInvalid = !form.getFieldValue('username') || form.getFieldValue('username').length === 0
        const passwordInvalid = !form.getFieldValue('password') || form.getFieldValue('password').length === 0
        if (userInvalid) {
          setUserValid(false)
        } else {
          setUserValid(true)
        }
        if (passwordInvalid) {
          setPasswordValid(false)
        } else {
          setPasswordValid(true)
        }
        if (userInvalid || passwordInvalid) {
          return
        }
        setLoading(true)
        signInWithEmailAndPassword(
          getAuth(),
          form.getFieldValue('username'),
          form.getFieldValue('password'),
        ).then(e => {
          window.location.href = '/stack';
        }).catch(e => {
          setActionError(e.message);
        }).finally(() => setLoading(false));
      }}
      onCancel={() => {
        form.resetFields()
        setActionError('')
        setVisible(false)
      }}
      okButtonProps={{loading:loading}}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 6,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={()=>undefined}
        onFinishFailed={()=>undefined}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="username"
          onChange={()=>setUserValid(!(!form.getFieldValue('username') || form.getFieldValue('username').length === 0))}
          validateStatus={!userValid && 'error'}
        >
          <Input className="modal-text-input" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          onChange={()=>setPasswordValid(!(!form.getFieldValue('password') || form.getFieldValue('password').length === 0))}
          validateStatus={!passwordValid && 'error'}
        >
          <Input.Password className="modal-text-input"/>
        </Form.Item>

        {actionError && <div style={{textAlign: 'center', marginBottom: '1rem', color: 'red'}}>{`Error: ${actionError === 'Firebase: Error (auth/invalid-email).' ? 'Invalid email address' : (actionError.includes('auth/user-not-found') || actionError.includes('auth/wrong-password')) ? 'Invalid username or password' : 'please try again.'}`}</div>}

        <div style={{marginBottom: '1rem'}}>
        <button className="gsi-material-button" onClick={() => {
              const provider = new GoogleAuthProvider();
              signInWithPopup(getAuth(), provider)
              .then((result) => {
                window.location.href = '/stack';
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
              }).catch((error) => {
                console.log(error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
              });
            }}>
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{display: "block"}}>
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span className="gsi-material-button-contents">Sign in with Google</span>
            <span style={{display: 'none'}}>Sign in with Google</span>
          </div>
        </button>
      </div>
      {/* <div style={{marginBottom: '1rem'}}>
        <button className="gsi-material-button">
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg style={{marginTop: '-0.2rem', marginLeft: '-1px'}} xmlns="http://www.w3.org/2000/svg"  viewBox="20 0 50 50" width="48px" height="28px"><path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/><path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"/></svg>
            </div>
            <span className="gsi-material-button-contents">Sign in with Facebook</span>
            <span style={{display: 'none'}}>Sign in with Facebook</span>
          </div>
        </button>
      </div> */}

        <div style={{textAlign:'center'}}>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            style={{display: 'inline'}}
            noStyle={true}
          >
            <Checkbox defaultChecked={false}>Remember me</Checkbox>
          </Form.Item>
        </div>

        <div style={{marginTop: '1.5rem', fontSize: 12, textAlign: 'center'}}>Not a member? <a className="my-href" onClick={() => {
          setVisible(false)
          setSignupVisible(true)
        }}>Signup</a></div>
  </Form>
    </Modal>
  )
}

export default SignIn
