import React, {useState, useEffect} from 'react';

import {
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';

import { sortBy } from 'lodash';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { registeredTechnologiesState, userSubscriptionsState,userGithubSubscriptionsState,registeredGithubReposState } from '../atoms/atoms';

import TechCard, { TechStatus } from '../components/TechCard/TechCard';
import GithubRepoCard from '../components/GithubRepoCard/GithubRepoCard';
import {TechsGroup} from './Tables';

function Premium() {
  // const registeredTechnologies = useRecoilValue(registeredTechnologiesState)
  const { contents: userSubscriptions, state: userSubscriptionsLoading } = useRecoilValueLoadable(userSubscriptionsState)
  const { contents: userGithubSubscriptions, state: userGithubSubscriptionsLoading } = useRecoilValueLoadable(userGithubSubscriptionsState)
  // const registeredGithubRepos = useRecoilValue(registeredGithubReposState)

  // if (userSubscriptionsLoading === 'loading' || userGithubSubscriptionsLoading === 'loading') {
  //   return <div style={{textAlign: 'center', marginTop: '2rem'}}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>
  // }

  const [techStatuses, setTechStatuses] = useState(userSubscriptions.length > 0 ? Object.fromEntries(userSubscriptions.map((x) => [x.id, TechStatus.Active])): {});

  useEffect(() => {
    if (userSubscriptionsLoading !== 'hasValue') return
    setTechStatuses(Object.fromEntries(userSubscriptions.map((x) => [x.id, TechStatus.Active])))
  }, [userSubscriptions, userSubscriptionsLoading])
  
  const [myStyle, setMyStyle] = useState({ opacity: 0, transform: 'scale(1.01)' })
  useEffect(() => {
    setMyStyle({ opacity: 1, transform: 'scale(1)' })
  }, [])
  
  const [myStyle2, setMyStyle2] = useState({ opacity: 0, transform: 'scale(1.01)' })
  useEffect(() => {
    if (userSubscriptionsLoading !== 'hasValue') return
    setTimeout(() => setMyStyle2({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [userSubscriptionsLoading])
  
  const [myStyle3, setMyStyle3] = useState({ opacity: 0, transform: 'scale(1.01)' })
  useEffect(() => {
    if (userGithubSubscriptionsLoading !== 'hasValue') return
    setTimeout(() => setMyStyle3({ opacity: 1, transform: 'scale(1)' }), 1)
  }, [userGithubSubscriptionsLoading])

  return (
    <div style={{...myStyle, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
      <div className="tech-section-header">Technologies I'm following</div>
      {userSubscriptionsLoading === 'loading' && <div><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>}
      {userSubscriptionsLoading === 'hasError' && <>Something went wrong</>}
      <div style={{...myStyle2, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
        {
          userSubscriptions.length === 0 ? <div style={{fontSize: 13}}>Nothing here at the moment.<br />Pleases select technologies from the <a href="/marketplace">Marketplace</a>.</div> :
            <TechsGroup techs={sortBy(userSubscriptions, (x)=>x.name.toLocaleLowerCase())} techStatuses={techStatuses} openable />
        }
      </div>
      <div className="tech-section-header" style={{marginTop: (userSubscriptionsLoading !== 'hasValue' || userSubscriptions.length === 0) ? '2rem' : 0}}>GitHub Repositories I'm following</div>
      {userGithubSubscriptionsLoading === 'loading' && <div><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>}
      {userGithubSubscriptionsLoading === 'hasError' && <>Something went wrong</>}
      <div style={{...myStyle3, transition: 'opacity 0.5s ease, transform 0.3s ease'}}>
        {userGithubSubscriptionsLoading === 'hasValue' && userGithubSubscriptions.length === 0 && <div style={{fontSize: 13}}>Nothing here at the moment.<br />Pleases add new repositories from the <a href="/github">GitHub section</a>.</div>}
        {userGithubSubscriptionsLoading === 'hasValue' && 
          userGithubSubscriptions.map((repo) => (
            <GithubRepoCard key={repo} repoName={repo} techStatus={TechStatus.Active} openable />
          ))
        }
      </div>
    </div>
  )
}
  
export default Premium;